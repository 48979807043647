.filter-main1 {
    display: flex;
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    margin: 18px 32px;
    /* border: 0.5px solid #d02c3047; */
}

.filter-main1 > div {
    margin: 10px;
}

/* .filter-main1 .MuiFormLabel-root, .filter-main .MuiFormLabel-root:after, .filter-main .MuiFormLabel-root.Mui-focused {
    color: #000000;
    font-family: AzoSans-Regular;
} */

.filter-main1 .MuiInput-underline {
    border-bottom: 2px solid #000000;
    width: 200px;
}


/* .filter-main1 .MuiInput-underline:after {
    border-bottom: 2px solid #000000;
} */

.filter-main1 .MuiButton-outlined {
    border:  1px solid #000000;
    font-family: AzoSans-Regular;
}

.filter-main1 .MuiButton-label {
    font-size: 15px;
    margin: 4px 2px 2px 2px;
    font-family: AzoSans-Regular;
    color: #000000;
    text-decoration: none;
}

.filter-main1 .MuiInput-underline:before{
    border-bottom: none;
}

.filter-jnumber,.filter-valueCode,.filter-cardBarCode,.filter-fileName{
    background-color: #f1f1f1;
    height: 75px;
    /* display: flex;
    justify-content: center; */
    /* width: 250px; */
    border: 2px solid #e4e4e4;
    box-shadow: 0 3px 12px #e4e4e4;
    margin:8px;
    text-align: left;
    position: relative;
}

.search-font{
    color: #cf2f44;
}

.filter-cardType{
    background-color: #f1f1f1;
    height: 75px;
    /* display: flex; */
    /* justify-content: center; */
    /* width: 250px; */
    border: 2px solid #e4e4e4;
    box-shadow: 0 3px 12px #e4e4e4;
    margin:8px;
    text-align: left;
    position: relative;
}
.MuiInput-underline.Mui-disabled:before{
    border-bottom-style: none !important;
}
.select-label{
    top: 0;
    left: 0;
    position: absolute;
    transform: translate(0, 24px) scale(1);
}

.filter-jnumber>div,.filter-valueCode>div,.filter-cardType>div,.filter-cardBarCode>div,.filter-fileName>div {
    margin: 10px;
}

.filters,.row1{
    display: flex;
    flex-direction: column;
}
.filters{
    /* width: 18%;     */
    align-self: baseline;
}
.row1{
    width: 82%;
}


.filter-daterange {
    display: flex;
    background-color: #f1f1f1;
    height: 75px;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 600px !important;
    border: 2px solid #e4e4e4;
    box-shadow: 0 3px 12px #e4e4e4;
    margin:8px;
}


.filter-daterange>div {
    margin: 10px;
}

.search-btn{
    margin-left: 20px !important;
}

.no-data{
    position: relative;
    top: 50px;
    font-size: xx-large;
    color: #cf2f44;
}

.search-criteria{
    font-size: larger;
    /* text-align: left; */
}