.datagrid-root {
    width: 100%;
}

.datagrid-container {
    max-height: 630px;
    margin-top: 50px;
}

.app-grid-link {
    color: blue !important;
}