.MuiDialog-paperWidthSm{
    max-width: 1200px !important;
}

.MuiDialogContentText-root{
    margin-top: -100px !important;
}

table, th, td {
  border-collapse: collapse;
}

th, td {
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid rgb(250, 235, 215);
  /* border-left: 1px solid rgb(173, 143, 143);
  border-right: 1px solid rgb(173, 143, 143); */
}

tr:hover{
    background-color: rgba(250, 235, 215,0.7);
}

table{
    width: 75%;
}
.title-centre{
    text-align: center !important;
}

.table-container{
    display: flex;
    justify-content: center;
}

.app-image{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}