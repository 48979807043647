.stacked-bar-graph #container {
    height: 400px; 
  }
  
  .stacked-bar-graph .highcharts-figure, .stacked-bar-graph .highcharts-data-table table {
    min-width: 310px; 
    max-width: 1200px;
    margin: 1em auto;
  }
  
  .stacked-bar-graph .highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #EBEBEB;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
  }
  .stacked-bar-graph .highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
  }
  .stacked-bar-graph .highcharts-data-table th {
      font-weight: 600;
    padding: 0.5em;
  }
  .stacked-bar-graph .highcharts-data-table td,.stacked-bar-graph .highcharts-data-table th,.stacked-bar-graph .highcharts-data-table caption {
    padding: 0.5em;
  }
  .stacked-bar-graph .highcharts-data-table thead tr,.stacked-bar-graph .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
  }
  .stacked-bar-graph .highcharts-data-table tr:hover {
    background: #f1f7ff;
  }

  .stacked-bar-graph {
      width: 100%;
  }